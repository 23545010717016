export default {
    coupons: {
        zhCN: {
            number_order: '序号',
            coupons_number: '券号',
            coupon_desc: '优惠券描述',
            coupons_amount: '优惠券金额',
            status: '使用状态',
            operator: '操作人',
            action: '操作',
            upload_time: '上传时间',
            template_name: 'Coupon Template.xlsx',
            sales_order: '销售订单',
            err_saleId: '暂无关联使用的销售单',
        },
        enUS: {
            number_order: 'NO.',
            coupons_number: 'Coupons No.',
            coupon_desc: 'Coupon Desc',
            status: 'Status',
            operator: 'Operator',
            coupons_amount: 'Coupons Amount',
            action: 'Action',
            upload_time: 'Upload Time',
            template_name: 'Coupon Template.xlsx',
            sales_order: 'Sales Order',
            err_saleId: 'No associated use of sales orders',
        },
    },
    salesTarget: {
        zhCN: {
            number_order: '序号',
            group: '组',
            dt_group: '组',
            dealer_code: '经销商代码',
            dt_code: '经销商代码',
            dealer_name: '经销商名称',
            rebate_type: '返利类型',
            related_kpi_policy_name: '相关KPI政策名称',
            kpi_policy_name: '相关KPI政策名称',
            sales_target: '销售目标',
            operator: '操作人',
            action: '操作',
            upload_time: '上传时间',
            time: '时间',
            approvalOpinion: '审批意见',
            approval_status: '审批状态',
            old_sales_target: '旧销售目标',
            new_sales_target: '新销售目标',
            sales_target_edit: '销售目标编辑',
            modifyRemark: '修改意见',
            remark: '拒绝原因',
            please_input_kpi: '请填写相关KPI政策名称',
            bothNone: '经销商代码和组名不能同时为空',
            not_edit: '销售目标不存在，请新增一条',
            batch_pass: '批量通过',
            please_batch: '请先勾选批量审核的销售目标',
            salesTarget: '销售目标',
            group_code: '组号',
            material_code: '物料号',
            parameter_name: '参数名称',
        },
        enUS: {
            number_order: 'No.',
            group: 'Group',
            dt_group: 'DT Group',
            dealer_code: 'Dealer Code',
            dt_code: 'DT Code',
            dealer_name: 'Dealer Name',
            rebate_type: 'Rebate Type',
            related_kpi_policy_name: 'Related KPI Policy Name',
            kpi_policy_name: 'Quarterly Target Name',
            sales_target: 'Sales Target',
            operator: 'Operator',
            action: 'Action',
            upload_time: 'Upload Time',
            time: 'Time',
            approvalOpinion: 'Approval Opinion',
            approval_status: 'Approval Status',
            old_sales_target: 'Original Sales Target',
            new_sales_target: 'New Sales Target',
            sales_target_edit: 'Sales Target Edit',
            modifyRemark: 'Modify Remark',
            remark: 'Reject Remark',
            please_input_kpi: '请填写相关KPI政策名称',
            bothNone: 'Group and Dealer Code cannot be empty at the same time',
            not_edit: 'Sales target does not exist,please add it',
            batch_pass: 'Batch Pass',
            please_batch: 'Please check the sales target for batch audit first',
            salesTarget: 'Sales Target',
            group_code: 'Group Code',
            material_code: 'Material Code',
            parameter_name: 'Parameter Name',
        },
    },
    nonCompliantDealers: {
        zhCN: {
            number_order: '序号',
            dealer_code: '经销商代码',
            dealer_name: '经销商名称',
            quarter: '季度',
            order_type: '订单类型',
            start_date: '开始时间',
            end_date: '结束时间',
            status: '状态',
            operator: '操作人',
            action: '操作',
            upload_time: '上传时间',
            update_time: '更新时间',
            create_time: '创建时间',
            non_compliant_dealers: '不达标经销商',
            set_types_of_orders_in_rebates: '标准返利订单类型',
            order_type_to_participate: '请选择参与返利的订单类型',
            validity_date: '有效期',
            dealers_choose_more: '可以选择一个或者多个经销商',
            training_margin: '售后',
            stocking_margin: '销售',
            terms: '比例',
            stdMargin: 'Std Margin',
            stdMarginTerm: 'Std Margin Term',
            limit: '请先选择季度',
            all_up_to_standard: '达标显示',
            training_all_up_to_standard: '培训达标',
            stocking_all_up_to_standard: '库存达标',
            upperMargin: '上下限',
            confirm_submit: '确认提交吗',
            pleaseSelectAtLeastOneItem: '请至少选择一项',
            click_pass: '点击"通过"按钮通过所有选定项目',
            comment: '审批意见',
            approval_status: '审批状态',
            submit_person: '提交人',
            submit_time: '提交时间',
            reject_confirm: '请确认提交',
            reasons_for_non_compliance: '不达标原因',
            reason_for_non_compliance_is_required: '不达标原因必填',
            date: '日期',
            after_sales_required: '售后必填',
            sales_required: '销售必填',
            to_confirm: '请确认提交',
        },
        enUS: {
            number_order: 'No.',
            dealer_code: 'Dealer Code',
            dealer_name: 'Dealer Name',
            quarter: 'Quarter',
            order_type: 'Order Type',
            start_date: 'Start Date',
            end_date: 'End Date',
            status: 'Status',
            operator: 'Operator',
            action: 'Operation',
            upload_time: 'Upload Time',
            update_time: 'Update Time',
            create_time: 'Create Time',
            non_compliant_dealers: 'Non-compliant dealers',
            set_types_of_orders_in_rebates: 'Set types of orders in rebates',
            order_type_to_participate:
                'Please select the order type to participate in the standard rebate',
            validity_date: 'Validity Date',
            dealers_choose_more: 'Dealers can choose more than one',
            training_margin: 'After-sales',
            stocking_margin: 'Sales',
            terms: 'Terms',
            stdMargin: 'Std Margin',
            stdMarginTerm: 'Std Margin Term',
            limit: 'Please select the quarter first',
            all_up_to_standard: 'Qualified Declaration',
            training_all_up_to_standard: 'After-sales',
            stocking_all_up_to_standard: 'Sales',
            upperMargin: 'upper Margin',
            confirm_submit: 'Do you confirm submission',
            pleaseSelectAtLeastOneItem: 'Please select at least one item',
            click_pass: 'Click ‘Pass’ to pass all of selected items',
            comment: 'Reasons for rejection',
            approval_status: 'Approval Status',
            submit_person: 'Submit Person',
            submit_time: 'Submit Time',
            reject_confirm: 'Please confirm the submission',
            reasons_for_non_compliance: 'Reasons for non-compliance',
            reason_for_non_compliance_is_required: 'Reason for non-compliance is required',
            date: 'Date',
            after_sales_required: 'After-sales is required',
            sales_required: 'Sales is required',
            to_confirm: 'Please confirm the submission',
        },
    },
    rebateList: {
        zhCN: {
            number_order: '序号',
            vin: 'VIN',
            dealer_code: '经销商代码',
            dealer_name: '经销商名称',
            rebate_type: '返利类型',
            model: '车型',
            reg_date: '注册日期',
            reg: '注册号',
            transferred_date: '转让日期',
            action: '操作',
            upload_time: '上传时间',
            santander_sales_order: '桑坦德销售订单',
            vehicle_description: '车辆描述',
            supplier_name: '供应商名称',
            quote_created_date: '报价创建日期',
            vrb_amount: 'VRB金额',
            vat: '税额',
            gross: '总金额',
            related_dcs_sales_order: '相关DCS销售订单',
            rom: '存储器',
            order_no: '订单号',
            order_type: '订单类型',
            price_term: '价格条款',
            receive_time: '发布时间',
            order_date: '订购日期',
            order_status: '订单状态',
            model_version: '型号/版本',
            reg_cancel_date: '注销日期',
            channel: '渠道',
            colour: '颜色',
            payment_type: '付款方式',
            coupons_no: '优惠券编码',
            credit_company: '信贷公司',
            basic_price: '基础价格',
            paint_price: '喷漆费用',
            credit_company_exclusive: '信贷公司专用',
            delivery_price: '运费',
            total_price: '总价',
            mgmfs_invoice: '开票金额',
            currency: '币种',
            asset_cash_price: 'Asset Cash Price (£)',
            original_apr: 'Original APR %',
            original_term: 'Original Term (months)',
            amount_to_finance: 'Amount to Finance (£)',
            fda: 'FDA (£)',
            Subsidy: 'Subsidy (£)',
            product_name: 'Product Name',
        },
        enUS: {
            number_order: 'No.',
            vin: 'VIN',
            dealer_code: 'Dealer Code',
            dealer_name: 'Dealer Name',
            rebate_type: 'Rebate Type',
            model: 'Model',
            reg_date: 'Reg Date',
            reg: 'Reg',
            transferred_date: 'Transferred Date',
            action: 'Action',
            upload_time: 'Upload Time',
            santander_sales_order: 'Creidt Company :  Santander',
            vehicle_description: 'Vehicle Description',
            supplier_name: 'Supplier Name',
            quote_created_date: 'Quote Created Date',
            vrb_amount: 'VRB Amount ',
            vat: 'VAT',
            gross: 'Gross',
            related_dcs_sales_order: 'Related DCS Sales Order',
            rom: 'ROM',
            order_no: 'Order No',
            order_type: 'Order Type',
            price_term: 'Price Term',
            receive_time: 'Receive Time',
            order_date: 'Order Date',
            order_status: 'Order Status',
            model_version: 'Model/Version',
            reg_cancel_date: 'Reg Cancel Date',
            channel: 'Channel',
            colour: 'Colour',
            payment_type: 'Payment Type',
            coupons_no: 'Coupons No',
            credit_company: 'Credit Company',
            basic_price: 'Basic Price',
            paint_price: 'Paint Price',
            credit_company_exclusive: 'Credit Company Exclusive',
            delivery_price: 'Delivery Price',
            total_price: 'Total Price',
            mgmfs_invoice: 'MGMFS Invoice',
            currency: 'Currency',
            asset_cash_price: 'Asset Cash Price (£)',
            original_apr: 'Original APR %',
            original_term: 'Original Term (months)',
            amount_to_finance: 'Amount to Finance (£)',
            fda: 'FDA (£)',
            Subsidy: 'Subsidy (£)',
            product_name: 'Product Name',
            credit_company_sales_order: 'Credit Company Sales Order',
        },
    },
    basicPrice: {
        zhCN: {
            vehicle_material_code: '物料号',
            material_description: '物料描述',
            series_code: '车系代码',
            series: '车系',
            gear: '变速箱',
            trim_version: '内饰版本',
            paint: '喷漆',
            basic_price: '基准价格',
            start_date: '开始日期',
            end_date: '结束日期',
            validity_date: '有效期',
            template_name: 'Basic Price Template.xlsx',
            approval_status: '审批状态',
            type: '类型',
            added: '新增',
            edited: '修改',
        },
        enUS: {
            vehicle_material_code: 'Vehicle Material Code',
            material_description: 'Material Description',
            series_code: 'Series Code',
            series: 'Series',
            gear: 'Gear',
            trim_version: 'Trim Version',
            paint: 'Paint',
            basic_price: 'Basic Price',
            start_date: 'Start Date',
            end_date: 'End Date',
            validity_date: 'Validity Date',
            template_name: 'Basic Price Template.xlsx',
            approval_status: 'Approval Status',
            type: 'Type',
            added: 'Added',
            edited: 'Edited',
        },
    },
    vehicleModelStandard: {
        zhCN: {
            parameter_code: '参数编码',
            parameter_name: '参数名称',
            bonus: 'bonus',
            term: 'term',
            is_valid: '是否有效',
            special_vehicle_type: '特殊车型',
            general_type: 'KPI类型',
            general_code: '通用编号',
            vin: 'VIN',
        },
        enUS: {
            parameter_code: 'Parameter Code',
            parameter_name: 'Parameter Name',
            bonus: 'bonus',
            term: 'term',
            is_valid: 'isValid',
            special_vehicle_type: 'Special Vehicle Type',
            general_type: 'KPI Type',
            general_code: 'General Code',
            vin: 'VIN',
        },
    },
};
